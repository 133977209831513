import Link from 'next/link'
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";

import { publicURL } from "../lib/ladder"

import styles from "./Footer.module.css"

export default function Footer({lander}) {
  return(
    <div className={styles["footer-container"] + (lander ? " " + styles["lander-footer"] : "")}>
      <div className="container-fluid">
        <hr className={styles["footer-hr"]}/>
        <div className="row">
          <div className={"col-xl-4 col-md-4 col-12"}>
            <div>
              <span className={styles["footer-copyright"]}>© 2024  Ladder for Work, Inc</span>
              <br />
              <span className={styles["footer-copyright"]}>120 West Trinity Place 4th Floor Decatur, GA 30030</span>
            </div>
          </div>
          <div className={"col-xl-4 col-md-4 col-12 text-center " + styles["footer-center"]}>
            <div>
              <Link href={publicURL() + "/terms"}><a rel="nofollow" className={styles["footer-link"]}>Terms</a></Link>
              <Link href={publicURL() + "/privacy"} rel="nofollow"><a rel="nofollow" className={styles["footer-link"]}>Privacy</a></Link>
              <Link href="mailto:hello@meetladder.com"><a rel="nofollow" className={styles["footer-link"]}>Contact</a></Link>
            </div>
          </div>
          <div className={"col-xl-4 col-md-4 col-12 " + styles["footer-right"]}>
            <div className={styles["right-floater"]}>
              <Link href={"https://www.instagram.com/meetladder"}><a className={styles["footer-link"]}><FaInstagram style={{fontSize: "1.3rem"}}/></a></Link>
              <Link href={"https://www.facebook.com/meetladder"}><a className={styles["footer-link"]}><FaFacebook style={{fontSize: "1.3rem"}}/></a></Link>
              <Link href={"https://twitter.com/meetladder"}><a className={styles["footer-link"]}><FaTwitter style={{fontSize: "1.3rem"}}/></a></Link>
              <Link href={"https://linkedin.com/company/meetladder"}><a className={styles["footer-link"]}><FaLinkedin style={{fontSize: "1.3rem"}}/></a></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}