import Head from 'next/head'
import { ChatWidget } from "@papercups-io/chat-widget";
import posthog from 'posthog-js';

import styles from './Layout.module.css'
import classNames from 'classnames'
import Navigator from './Navigator'
import UserManager from "../lib/userManager"
import ToastManager from "../lib/toastManager"
import { frontendURL } from "../lib/ladder"
import { isClient, isServer } from "../lib/generalUtils"

import Footer from "./Footer"

import React, { useEffect } from "react";
import { ToastContainer } from 'react-toastify';

function Layout({ children, height, user, color, hideNav, employerLogo, brandLink, lNav, domain, lander=false, WP = false, shortnav = false, classList }) {
  useEffect(() => buildStorytime(), [isClient()])
  useEffect(() => buildPosthog(), [isClient()])
  useEffect(() => capturePageView(), [isClient()])

  const capturePageView = () => {
    ToastManager.previewToast();
    
    if(isClient() && posthog && frontendURL() !== "localhost:8080") {
      posthog.capture('$pageview');
      
      if(UserManager.signedIn()) { 
        const user = UserManager.buildUser();

        posthog.identify(user.identifier);

        if(UserManager.isWorker()) {
          posthog.people.set({phoneNumber: user.phone_number, name: user.fname + " " + user.lname});
        } else {
          posthog.people.set({email: user.email, name: user.fname + " " + user.lname});
        }
      }
    }
  }
  
  const buildPosthog = () => {
    console.log("Build posthog")
    if(isClient() && frontendURL() !== "localhost:8080") {
      posthog.init("phc_IkHAKJZJuvjeMg0HjE1jEf96dlNxZ8tBoAlZfq9dABc", {
        api_host: 'https://app.posthog.com',
        loaded: () => {
          if(UserManager.signedIn()) { 
            const user = UserManager.buildUser();

            posthog.identify(user.identifier);

            if(UserManager.isWorker()) {
              posthog.people.set({phoneNumber: user.phone_number, name: user.fname + " " + user.lname});
            } else {
              posthog.people.set({email: user.email, name: user.fname + " " + user.lname});
            }
          }
        }
      });
    }
  }

  const buildPapercups = () => {
    const user = UserManager.buildUser();

    return(
      <ChatWidget
        title="Welcome to Ladder"
        subtitle= "Ask us anything in the chat window below!"
        primaryColor="#333333"
        greeting={`Hi ${user.fname ? user.fname : "there"}! Let us know if you have any questions. We are here for you!`}
        newMessagePlaceholder="Start typing..."
        accountId="9e2e635b-8065-4aab-9408-fd9933d3fb91"
        baseUrl="https://app.papercups.io"
      />
    )
  }

  const buildStorytime = () => {
    const user = UserManager.buildUser();

    if(!user || (!user.email && !user.phone_number)) return;

    const { Storytime } = require('@papercups-io/storytime');
    const st = Storytime.init({
      accountId: '9e2e635b-8065-4aab-9408-fd9933d3fb91',
      baseUrl: 'https://app.papercups.io',
      customer: {
        name: user.fname + " " + user.lname,
        email: user.email || user.phone_number,
        external_id: user.identifier 
      }
    });
  }

  const buildGoogleMaps = () => {
    return(<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBySuf9B8bDhcf87iMv0G_HThtEYO9R3rI&libraries=places"></script>)
  }

  const getContrast = (hexcolor) => {
    if(!hexcolor) return "#333";
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
      hexcolor = hexcolor.slice(1);
    }
  
    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
      hexcolor = hexcolor.split('').map(function (hex) {
        return hex + hex;
      }).join('');
    }
  
    // Convert to RGB value
    var r = parseInt(hexcolor.substr(0,2),16);
    var g = parseInt(hexcolor.substr(2,2),16);
    var b = parseInt(hexcolor.substr(4,2),16);
  
    // Get YIQ ratio
    var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  
    // Check contrast
    return (yiq >= 128) ? 'black' : 'white';
  
  };

  const buildGA = () => {
    if(!isClient() || frontendURL() === "localhost:8080") return;

    return(
      <>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-MTPR7M7Q0E"></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-MTPR7M7Q0E');
            gtag('config', 'AW-389304859');
          `
        }}
      />
      </>
    )
  }

  const layoutClassList = classNames([
    styles['header-wrapper'],
    classList,
    {
      'layout--wp': WP,
      'layout--shortnav': shortnav,
    }
  ])

  return (
    <div className={layoutClassList}>
      <Head>
        {buildGoogleMaps()}
        {buildGA()}
      </Head>
      {buildPapercups()}
      <ToastContainer limit={1}/>
      <div className={styles["header-shape"]} style={{height: height || 450, background: (color ? color : "")}}></div>
      <Navigator userData={user} hide={hideNav} textColor={getContrast(color)} employerLogo={employerLogo} brandLink={brandLink} lNav={lNav} domain={domain}/>
      <div style={{"position": "relative", "zIndex": 1}}>
        {children}
      </div>
      <Footer lander={lander}/>
    </div>
  )
}

export default Layout
