import { toast } from 'react-toastify';
import CookieManager from "./cookieManager"

export default class ToastManager {
  static options = {
     position: toast.POSITION.TOP_CENTER,
     pauseOnHover: false,
     pauseOnFocusLoss: false,
     toastId: "same-id"
  }

  static unauthorizedAccessMessage() {
    CookieManager.setCookie("toast", "error|Page not found!", 1)
  }

  static loginRequiredMessage() {
    CookieManager.setCookie("toast", "error|You must log in to access this page!", 1)
  }

  static logoutMessage() {
    CookieManager.setCookie("toast", "success|You have successfully logged out!", 1)
  }

  static alreadyLoggedIn() {
    CookieManager.setCookie("toast", "error|You are already logged in!", 1)
  }

  static successfullLogin() {
    CookieManager.setCookie("toast", "success|Welcome back!", 1)
  }

  static successfullJobCreation() {
    CookieManager.setCookie("toast", "success|You have successfully created the job!", 1)
  }

  static successfullCandidateCreation() {
    CookieManager.setCookie("toast", "success|You have successfully created the candidate!", 1)
  }

  static successfullEmployerCreation() {
    CookieManager.setCookie("toast", "success|You have successfully created the employer!", 1)
  }

  static successfullWorkerCreation() {
    CookieManager.setCookie("toast", "success|You have successfully created the worker!", 1)
  }

  static successfullWorkerUpdate() {
    CookieManager.setCookie("toast", "success|You have successfully updated the worker!", 1)
  }

  static successfullHRManagerCreation() {
    CookieManager.setCookie("toast", "success|You have successfully created the HR Manager!", 1)
  }

  static successfullHRManagerUpdate() {
    CookieManager.setCookie("toast", "success|You have successfully updated the HR Manager!", 1)
  }

  static successfullJobUpdate() {
    CookieManager.setCookie("toast", "success|You have successfully updated the job!", 1)
  }

  static successfullCampaignCreate() {
    CookieManager.setCookie("toast", "success|You have successfully created the campaign!", 1)
  }

  static successfullCampaignUpdate() {
    CookieManager.setCookie("toast", "success|You have successfully modified the campaign!", 1)
  }

  static unavailableJobApp() {
    CookieManager.setCookie("toast", "error|This job doesn't exist!", 1)
  }

  static invalidJob() {
    CookieManager.setCookie("toast", "error|You can only add candidates to active jobs!", 1)
  }

  static successfullUserUpdate() {
     CookieManager.setCookie("toast", "success|You have successfully updated your account!", 1);
  }

  static successfulSendTokenRequest(){
      toast.success("Success. Please check your mail.", this.options);
  }
  static successfullProfileUpdate() {
    toast.success("You have successfully updated your profile!", this.options);
 }

 static successfullApplyInvitesSent() {
  toast.success("You have successfully sent the apply invites!", this.options);
 }

  static successfullEmployerUpdate() {
    CookieManager.setCookie("toast", "success|You have successfully updated the employer!", 1)

    // toast.success("You have successfully updated the employer information!", this.options)
  }

  static successfullJobApply() {
    toast.success("You have successfully applied to the job", this.options)
  }

  static successfulJobRenew() {
    CookieManager.setCookie("toast", "success|You have successfully renewed the job!", 1)
    // toast.success("You have successfully renewed the job", this.options)
  }

  static successfullOfferSave() {
    toast.success("You have successfully saved the offer!", this.options)
  }

  static successfulNotificationSend() {
    toast.success("We have notified the team about your upgrade request. They will be in touch shortly.", this.options)
  }

  static successfullAdminWorkerApply(name) {
    toast.success(`You have successfully applied to ${name}the job`, this.options)
  }

  static successfulCopy(text) {
    toast.success(`${text} successfully copied.`, this.options)
  }

  static successfullAccountCreation() {
    toast.success(`Your profile was successfully generated. Please login below.`, this.options)
  }

  static successfulTemplateSave() {
    toast.success(`You have successfully saved the message template.`, this.options)
  }

  static successfullCheckout() {
    toast.success(`You have successfully setup your Ladder plan.`, this.options)
  }

  static successfulResetPassword() {
      toast.success('You have successfully changed your password', this.options)
  }
  static candidateStatusChanged(status, candidate) {
    toast.dismiss();

    if(status === "Offer Processing") {
      toast.success("You successfully started the new hire process for " + candidate, this.options)
    } else if(status === "Company Passed") {
      toast.success("You successfully passed on " + candidate, this.options)
    } else if(status === "Interview Requested") {
      toast.success("You successfully requested an inteview with " + candidate, this.options)
    } else {
      toast.success("You successfully set " + candidate + "'s status to " + status , this.options)
    }
  }

  static updatedJobStatus(status) {
    toast.dismiss();

    if(status === "Active") {
      toast.success("You successfully activated the job!", this.options)
    } else if(status === "Inactive") {
      toast.success("You successfully deactivated the job!", this.options)
    } else if(status === "Draft") {
      toast.success("You successfully set the job as a draft!", this.options)
    }
  }

  static errorToast(errors) {
    toast.dismiss();

    toast.error(errors, this.options)
  }

  static previewToast() {
    // toast.dismiss();

    const toastMessage = CookieManager.getCookie("toast")

    if(toastMessage) {
      const items = toastMessage.split("|");

      if(items[0] === "error") {
        toast.error(items[1], this.options)
        console.log("ERROR TOAST")
      } else if(items[0] === "success") {
        toast.success(items[1], this.options)
        console.log("SUCCESS TOAST")
      }

      CookieManager.deleteCookie("toast")
    }
  }
}