import { toast } from 'react-toastify';
import cookies from 'next-cookies'  
import jwt_decode from "jwt-decode";

import CookieManager from "./cookieManager"
import ToastManager from "./toastManager"

export default class UserManager {
  static getAuthToken() {
    try {
      return CookieManager.getCookie("auth_token");
    } catch (error) {
      return undefined;
    }
  }
 
  static getAuthTokenServer(context) {
    return cookies(context).auth_token;
  }

  static removeAuthToken() {
    CookieManager.deleteCookie("auth_token");
  }

  static setAuthToken(auth_token) {
    CookieManager.setCookie("auth_token", auth_token, 300);
  }

  static buildUser(loc, context) {
    let authToken = ""

    if(loc === "server") {
      authToken = this.getAuthTokenServer(context);
    } else {
      authToken = this.getAuthToken();
    }

    if(!authToken || authToken === null) {
      return {};
    } else {
      try {
        return jwt_decode(authToken)["user"];
      } catch (error) {
        return undefined;
      }
    }
  }
  

  static identifier() {
    if(!this.signedIn()) return;
    return this.buildUser().identifier; 
  }

  static email() {
    if(this.signedIn()) return this.buildUser().email
  }

  static signedIn() {
    return (this.getAuthToken() !== null && this.getAuthToken() !== "" && this.getAuthToken() !== undefined)
  }

  static isOwner() {
    return this.signedIn() && this.buildUser().role === "owner"
  }

  static isEmployee() {
    return this.signedIn() && this.buildUser().role === "employee"
  }

  static isAdmin() {
    return this.signedIn() && this.buildUser().type === "admin"
  }

  static isEmployer() {
    return this.signedIn() && this.buildUser().type === "employer"
  }

  static isHRManager() {
    return this.signedIn() && this.buildUser().type === "employer"
  }

  static isWorker() {
    return this.signedIn() && this.buildUser().type === "worker"
  }

  static isOrgManager() {
    return this.signedIn() && this.buildUser().type === "org_manager"
  }

  static plan() {
    const user = this.buildUser();
    
    if(user.employer && user.employer.plan) {
      return user.employer.plan;
    } else {
      return null;
    }
  }

  // Send the user to their specific home page (/admin for admins, /jobs for hr managers, etc)
  static routeHome(router) {    
    if(!this.signedIn()) {
      ToastManager.loginRequiredMessage();
      router.push("/login")
    } else if(this.isAdmin()) {
      router.push("/admin")
    } else if(this.isHRManager()){
      router.push("/jobs")
    } else if(this.isOrgManager()){
      router.push("/organizations/" + this.buildUser().organizations[0].identifier)
    } else if(this.isWorker()) {
      router.push("/jobs")
    }
  }

  static logout() {
    this.removeAuthToken();
  }
}