import UserManager from "./userManager"
import ToastManager from "./toastManager"

export const backendURL = () => {
  if(process.env.NEXT_PUBLIC_NEXT_ENV === "development") {
    return "http://localhost:3000"
  } else if(process.env.NEXT_PUBLIC_NEXT_ENV === "staging") {
    return "https://" + process.env.NEXT_PUBLIC_LADDER_API_HOST + "." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  } else if(process.env.NEXT_PUBLIC_NEXT_ENV === "production"){
    return "https://api." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  }
}

export const frontendURL = () => {
  if(process.env.NEXT_PUBLIC_NEXT_ENV === "development") {
    return "http://localhost:8080"
  } else if(process.env.NEXT_PUBLIC_NEXT_ENV === "staging") {
    return "https://" + process.env.NEXT_PUBLIC_LADDER_APP_HOST + "." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  } else {
    return "https://app." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  }
}

export const publicURL = () => {
  if(process.env.NEXT_PUBLIC_NEXT_ENV === "development") {
    return "http://localhost:8080"
  } else if(process.env.NEXT_PUBLIC_NEXT_ENV === "staging") {
    return "https://" + process.env.NEXT_PUBLIC_LADDER_PUBLIC_HOST + "." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  } else {
    return "https://www." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  }
}

export const iecURL = () => {
  if(process.env.NEXT_PUBLIC_NEXT_ENV === "development") {
    return "http://localhost:8080"
  } else if(process.env.NEXT_PUBLIC_NEXT_ENV === "staging") {
    return "https://" + process.env.NEXT_PUBLIC_LADDER_IEC_HOST + "." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  } else {
    return "https://iec-atlanta." + process.env.NEXT_PUBLIC_LADDER_DOMAIN
  }
}


let BACKEND_URL = backendURL();
let FRONTEND_URL = frontendURL();

export const UserAPI = {
  salaryCalculator: async (data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/salary_calculator?filters=${JSON.stringify(data)}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  getAvailableFilters: async () => {
    const res = await fetch(`${BACKEND_URL}/v1/users/available_filters`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createHRManagerWithInviteToken: async (data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/create_with_invite_token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  sendInvite: async(authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/send_invite`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
      body: JSON.stringify(data)
    }).then(APIHandler.handleResponse)
      .catch(APIHandler.handleError)

    return res;
  },

  deleteHrManager: async(authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${identifier}/remove_hr_profile`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors'
    }).then(APIHandler.handleResponse)
      .catch(APIHandler.handleError)

    return res;
  },

  changeHrProfileRole: async(authToken, identifier, newRole) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${identifier}/change_role`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
      body: JSON.stringify({ new_role: newRole })
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)

    return res;
  },

  resetPassword: async(password, confirmPassword, resetToken) => {
    const data = { password: password, password_confirmation: confirmPassword }
    const res = await fetch(`${BACKEND_URL}/v1/users/reset_password?token=${resetToken}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)

    return res;
  },

  forgotPassword: async(email) => {
    const data = { email: email }
    const res = await fetch(`${BACKEND_URL}/v1/users/forgot_password`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)

    return res;
  },

  login: async (email, password) => {
    const data = { user: {email: email, password: password} }

    const res = await fetch(`${BACKEND_URL}/v1/users/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  sendAuthCode: async (phoneNumber) => {
    const data = { phone_number: phoneNumber }

    const res = await fetch(`${BACKEND_URL}/v1/users/send_auth_code`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  verifyAuthCode: async (phoneNumber, authCode) => {
    const data = { user: { phone_number: phoneNumber, auth_code: authCode } }

    const res = await fetch(`${BACKEND_URL}/v1/users/authenticate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  applications: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/applications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  profile: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  logout: () => {

  },

  manage: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/manage`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  update: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${identifier}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  editProfile: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/edit_profile`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  updateProfile: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/update_profile`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  publicProfile: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${encodeURI(identifier)}/public_profile/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  workPhotos: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${encodeURI(identifier)}/work_photos/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  sendProfileMessage: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/${identifier}/send_profile_message/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  onboardingFields: async () => {
    const res = await fetch(`${BACKEND_URL}/v1/users/onboarding_fields/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  notifications: async (authToken, page, perPage) => {
    const res = await fetch(`${BACKEND_URL}/v1/users/notifications?page=${page ? page : "1"}${perPage ? "&per_page=" + perPage : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
}

export const JobAPI = {
  rejectRecommendation: async (authToken, job_identifier, worker_identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${job_identifier}/recommendations/reject`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({ worker_identifier: worker_identifier }),
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  getRejectedCandidates: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/recommendations/rejected_list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  getInvitesCandidates: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/recommendations/invites_list`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  getRecommendationCandidates: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/recommendations`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  jobForSelect: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/jobs_for_select`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  handleImpression: async (auctionId, jobId) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${jobId}/handle_impression`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ auctionId: auctionId }),
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  handleClick: async (auctionId, jobId) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${jobId}/handle_click`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ auctionId: auctionId }),
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)
    return res;
  },

  all: async (authToken, employer, page, sortBy, status, search) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs?employer=${employer ? employer : ""}${page ? ("&page=" + (page + 1)) : ""}${sortBy ? ("&sort_item=" + sortBy.id) : ""}${sortBy ? ("&sort_dir=" + (sortBy.desc ? "desc" : "asc")) : ""}${search ? "&search=" + search : ""}${(status !== undefined)? "&status=" + status.value : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  allForHomepage: async () => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/index_for_homepage`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    })
        .then(APIHandler.handleResponse)
        .catch(APIHandler.handleError)

    return res;
  },

  fields: async (authToken, newJob) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/form_fields?${newJob ? "new_job=" + newJob : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  form: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/` + identifier + "/form", {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  create: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  update: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  delete: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  view: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  apply: async (authToken, identifier, phoneNumber) => {
    const data = { phone_number: phoneNumber }

    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/apply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + (authToken || ""),
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  quickApply: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/quick_apply`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + (authToken || ""),
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  candidates: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/candidates`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  recommended: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/recommended`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  search: async (authToken, page, sortBy, query, filters, fType, subdomain) => {
    const res = await fetch(BACKEND_URL + '/v1/jobs/search?'
        + 'page=' + page
        + (sortBy ? ("&sort_item=" + sortBy.id + "&sort_dir=" + (sortBy.desc ? "desc" : "asc")) : "")
        + (query ? "&query=" + query : "")
        + (filters && filters.length > 0 ? "&filters=" + JSON.stringify(filters) : "")
        + (fType && fType.length > 0 ? "&ftype=" + fType : "")
        + (subdomain && subdomain.length > 0 ? "&subdomain=" + subdomain : ""), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  renew: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/renew`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + (authToken || ""),
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  modifyCampaign: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/jobs/${identifier}/modify_campaign`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const CandidateAPI = {
  all: async (authToken, page, sortBy, search, filters) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates?page=${page ? (page + 1) : ""}${sortBy ? ("&sort_item=" + sortBy.id) : ""}${sortBy ? ("&sort_dir=" + (sortBy.desc ? "desc" : "asc")) : ""}${search ? "&search=" + search : ""}${filters && filters.length > 0 ? "&filters=" + JSON.stringify(filters) : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  fields: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates/form_fields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  create: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  updateStatus: async (authToken, identifier, status) => {
    const data = { status: status }

    const res = await fetch(`${BACKEND_URL}/v1/candidates/${identifier}/update_status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  delete: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates/` + identifier, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  view: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates/` + identifier, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  saveOffer: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates/` + identifier + "/offer", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const NoteAPI = {
  create: async (authToken, identifier, content) => {
    const res = await fetch(`${BACKEND_URL}/v1/candidates/${identifier}/notes/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({ content: content }),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const SubscriptionAPI = {
  dashboard: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/subscriptions/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  search: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/subscriptions/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const CampaignAPI = {
  show: async (authToken, id) => {
    const res = await fetch(`${BACKEND_URL}/v1/campaigns/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  dashboard: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/campaigns/dashboard`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  search: async (authToken, search, status) => {
    const res = await fetch(`${BACKEND_URL}/v1/campaigns/search`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({ search: search, status: status }),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const EmployerAPI = {
  verify: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/${identifier}/verify`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  showAdmin: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  registrate: async (data) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/registrate`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  update: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/${identifier}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  publicView: async (authToken, uname) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/view/${uname}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  searchConversations: async (authToken, query) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/search_conversations?query=${query}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  applyPoster: async (authToken, identifier, type) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/${identifier}/apply_poster?type=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  qrcode: async (authToken, identifier, type) => {
    const res = await fetch(`${BACKEND_URL}/v1/employers/${identifier}/apply_qrcode?type=${type}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

/*******************************/
/* API library for admin routes
/*******************************/

export const AdminAPI = {
  totalApplications: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/total_applications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  delete_employer: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/${identifier}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  delete: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/${identifier}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  referrerSourceByName: async (authToken, referrerSource) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/referrer_sources`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({referrer_sources: referrerSource}),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  referrerSourceTotal: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/referrer_sources_total/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  signInUsers: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/sign_in_users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  signUpUsers: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/sign_up_users/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  // Pulls all of the summary data from the Ladder API
  dashboard: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/dashboard/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  // Pulls all of the workers from the Ladder API
  workers: async (authToken, page, sortBy, globalFilter, filters) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers?page=${page + 1}${sortBy ? ("&sort_item=" + sortBy.id + "&sort_dir=" + (sortBy.desc ? "desc" : "asc")) : ""}${globalFilter ? "&query=" + globalFilter : ""}${filters && filters.length > 0 ? "&filters=" + JSON.stringify(filters) : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  worker: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  editWorker: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/${identifier}/edit`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  applyWorker: async (authToken, workerIdentifier, jobIdentifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/${workerIdentifier}/apply/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({ job_identifier: jobIdentifier }),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  inviteToApplyWorkers: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/invite_to_apply/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  workerFields: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/fields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createWorker: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  updateWorker: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/workers/${identifier}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createCandidate: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/candidates/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  employerSelectSearch: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/select_search`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  employerSelectJobs: async (authToken, employerIdentifier, workerIdentifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/${employerIdentifier}/job_search${workerIdentifier ? "?worker_identifier=" + workerIdentifier : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  employerFields: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/fields`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createEmployer: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  editEmployer: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/${identifier}/edit`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  hrManagers: async (authToken, page, sortBy, globalFilter) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/hr-managers?page=${page + 1}${sortBy ? ("&sort_item=" + sortBy.id + "&sort_dir=" + (sortBy.desc ? "desc" : "asc")) : ""}${globalFilter ? "&query=" + globalFilter : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  hrManager: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/hr-managers/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createHRManager: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/hr-managers/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  updateHRManager: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/users/hr-managers/${identifier}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  employers: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  viewEmployer: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/employers/` + identifier, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  organizations: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/organizations/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createOrganization: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/organizations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  editOrganization: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/organizations/${identifier}/edit`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  updateOrganization: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/admin/organizations/${identifier}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
}

export const ConversationAPI = {
  createInterviewViaRequest: async (data, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/` + identifier + "/interviews/create_interview_with_token", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  requestInterview: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/` + identifier + "/interviews/make_request", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  createInterview: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/` + identifier + "/interviews", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  viewAll: async (authToken, page, query, multiple) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations?page=${(page ? page : "1")}${query ? "&query=" + query : ""}${multiple ? "&multiple=" + multiple : ""}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  view: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  messages: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/${identifier}/messages`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
}

export const MessageAPI = {
  create: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/conversations/${identifier}/messages`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const MessageTemplateAPI = {
  viewAll: async (authToken) => {
    const res = await fetch(`${BACKEND_URL}/v1/message_templates/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  view: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/message_templates/${identifier}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  create: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/message_templates/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  update: async (authToken, identifier, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/message_templates/${identifier}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const LadderAPI = {
  submitDemoForm: async (data) => {
    const res = await fetch(`${BACKEND_URL}/v1/lander/demo_form`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  sitemapSlugs: async () => {
    const res = await fetch(`${BACKEND_URL}/v1/lander/sitemap_slugs`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
  landingWorkers: async (authToken, location, category) => {
    const res = await fetch(`${BACKEND_URL}/v1/lander/workers?location=${location}&category=${category}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  jobsAndFilters: async () => {
    const res = await fetch(`${BACKEND_URL}/v1/lander/jobs_and_filters`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const OrganizationAPI = {
  newCandidatesFromPeriod: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/total_candidates_from_periods/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  contractorMetrics: async (authToken, identifier, search, currentDay, previousDay) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/contractor_metrics/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(
        {
          search: search,
          select_dates_start: previousDay,
          select_dates_end: currentDay
        }
      ),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  newCandidatesOfCompanies: async (authToken, identifier, companyIds) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/new_candidates_of_companies/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify({company_ids: companyIds}),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  candidatesCount: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/companies_with_count_candidates/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  jobsPosted: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/jobs_posted/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  companies: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/companies`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  hires: async (authToken, identifier, search, currentDay, previousDay, companyIds) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/hires`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken,
      },
      body: JSON.stringify(
        {
          search: search,
          select_dates_start: previousDay,
          select_dates_end: currentDay,
          company_ids: companyIds
        }
      ),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res
  },

  stats: async (authToken, identifier) => {
    const res = await fetch(`${BACKEND_URL}/v1/organizations/${identifier}/stats`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      mode: 'cors',
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },
}

export const BillingAPI = {
  changePlan: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/billing/change_plan`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  deletePaymentMethod: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/billing/delete_payment_method`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  },

  attach: async (authToken, data) => {
    const res = await fetch(`${BACKEND_URL}/v1/billing/attach_payment_method`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + authToken
      },
      body: JSON.stringify(data),
      mode: 'cors'
    })
    .then(APIHandler.handleResponse)
    .catch(APIHandler.handleError)

    return res;
  }
}

export const WPPagesAPI = {
  home: async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  blog: async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/blog/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  blogPage: async (page) => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/blog/page/${page}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  post: async (post_slug) => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/blog/${post_slug}/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  workers: async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/workers/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  employers: async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/employers/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  },
  thankyou: async () => {
    const res = await fetch(`${process.env.NEXT_PUBLIC_LADDER_WP}/thank-you/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'text/html',
        'Requested-From': 'Ladder Frontend',
      },
    })
    .then(APIHandler.handleHtml)
    .catch(APIHandler.handleError)

    return res;
  }
}

class APIHandler {
  static handleJSON(res) {
    return res;
  }

  static handleError(res) {
    return { success: false, errors: [], statusCode: 500 }
  }

  static async handleResponse(res) {
    const statusCode = res.status;
    const json = await res.json();

    return { ...json, statusCode };
  }

  static async handleHtml(res) {
    const statusCode = res.status;
    const text = await res.text();

    const result = { text, statusCode }
    if (res.ok) result.success = true

    return result;
  }
}
