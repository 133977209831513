import Link from 'next/link'
import { useRouter } from 'next/router'

import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import React, { useState, useEffect } from "react";
import { IoIosAddCircle } from "react-icons/io";

import UserManager  from "../lib/userManager"
import { frontendURL }  from "../lib/ladder"

import styles from './Navigator.module.css'

export default function Navigator({userData, hide, employerLogo, textColor, brandLink, lNav, domain}) {
  const [user, setUser] = useState(userData || {})
  const [loggedIn, setLoggedIn] = useState(0)
  const route = useRouter();

  useEffect(() => {
    setUser(UserManager.buildUser());
    if(UserManager.signedIn()) setLoggedIn(1)
  }, [])

  const logoutUser = () => {
    UserManager.logout();
    route.push(frontendURL() + "/login");
  }

  const selectNav = (dropdownName) => {
    if(UserManager.isAdmin()) {
      return adminNavWithoutDropdowns(dropdownName);
    } else if(UserManager.isHRManager()) {
      return hrNav(dropdownName)
    } else if(UserManager.isWorker()) {
      return workerNav(dropdownName);
    } else if(UserManager.isOrgManager()) {
      return organizationsNav(dropdownName)
    }
  }

  const authenticatedNav = () => {
    const dropdownName = `${user['fname']} ${user['lname']}`;

    return (
      <Navbar.Collapse id="basic-navbar-nav">
        {selectNav(dropdownName)}
      </Navbar.Collapse>
    )
  }

  const adminNavWithoutDropdowns = (dropdownName) => {
    return(
      <>
        <Nav className="mr-auto">          
          <Link href="/admin/workers/" passHref>
            <Nav.Link className={styles['nav-link']}>Workers</Nav.Link>
          </Link>
          <Link href="/admin/hr-managers/" passHref>
            <Nav.Link className={styles['nav-link']}>HR Managers</Nav.Link>
          </Link>
          <Link href="/admin/employers/" passHref>
            <Nav.Link className={styles['nav-link']}>Employers</Nav.Link>
          </Link>
          <Link href="/admin/jobs/" passHref>
            <Nav.Link className={styles['nav-link']}>Jobs</Nav.Link>
          </Link>
          <Link href="/admin/candidates/" passHref>
            <Nav.Link className={styles['nav-link']}>Candidates</Nav.Link>
          </Link>
          <Link href="/admin/conversations/all" passHref>
            <Nav.Link className={styles['nav-link']}>Conversations</Nav.Link>
          </Link>
          <Link href="/admin/organizations/" passHref>
            <Nav.Link className={styles['nav-link']}>Organizations</Nav.Link>
          </Link>
          <NavDropdown title={ 
            <span className={styles['nav-dropdown-text']}>Income</span>
          } id="basic-nav-dropdown" style={{fontSize: "4rem !important"}}>
            <Link href="/campaigns" passHref>
              <NavDropdown.Item>Ad Campaigns</NavDropdown.Item>
            </Link>
            <Link href="/admin/subscriptions" passHref>
              <NavDropdown.Item>Subscriptions</NavDropdown.Item>
            </Link>
          </NavDropdown>
        </Nav>
        <Nav className={styles["right-padding"] + " ml-auto"}>
          <NavDropdown title={ 
            <span className={styles['nav-dropdown-text']}>{dropdownName}</span>
          } id="basic-nav-dropdown" style={{fontSize: "4rem !important"}}>
            <Link href="/account" passHref>
              <NavDropdown.Item>Manage Account</NavDropdown.Item>
            </Link>
            <a className="dropdown-item" onClick={() => logoutUser()} href="javascript:void(0)">Log out</a>
          </NavDropdown>
        </Nav>
      </>
    )
  }

  const hrNav = (dropdownName) => {
    return(
      <>
        <Nav className="mr-auto">
          <Link href="/jobs/" passHref>
            <Nav.Link className={styles['nav-link']}>Jobs</Nav.Link>
          </Link>
          <Link href="/candidates/" passHref>
            <Nav.Link className={styles['nav-link']}>Candidates</Nav.Link>
          </Link>
          <Link href="/conversations/all" passHref>
            <Nav.Link className={styles['nav-link']}>Conversations</Nav.Link>
          </Link>
          <Link href="/message-templates" passHref>
            <Nav.Link className={styles['nav-link']}>Templates</Nav.Link>
          </Link>
          <Link href="/profiles" passHref>
            <Nav.Link className={styles['nav-link']}>Profile Search</Nav.Link>
          </Link>
          <Link href="/campaigns" passHref>
            <Nav.Link className={styles['nav-link']}>Ad Campaigns</Nav.Link>
          </Link>
        </Nav>
        <Nav className={styles["right-padding"] + ' ml-auto'}>
          <Link href="/jobs/new" passHref>
            <Nav.Link className={styles['nav-link'] + " btn btn-primary " + styles['nav-btn']}><IoIosAddCircle style={{"marginBottom": "2px"}}/> Post a Job</Nav.Link>
          </Link>
          <NavDropdown
            title={<span className={styles['nav-dropdown-text']}>{dropdownName}</span>}
            id={"basic-nav-dropdown"}
            style={{fontSize: "4rem !important"}}>
            <Link href="/account" passHref><NavDropdown.Item>Manage Account</NavDropdown.Item></Link>
            <a className="dropdown-item" onClick={() => logoutUser()} href="javascript:void(0)">Log out</a>
          </NavDropdown>
        </Nav>
      </>
    )
  }

  const workerNav = (dropdownName) => {
    return(
      <>
        <Nav className="mr-auto">
          <Link href="/jobs" passHref>
            <Nav.Link className={styles['nav-link']}>Jobs</Nav.Link>
          </Link>
          <Link href="/applications/" passHref>
            <Nav.Link className={styles['nav-link']}>Applications</Nav.Link>
          </Link>
          <Link href={"/salary-calculator"} passHref shallow>
            <Nav.Link className={styles['nav-link']}>Wage Calculator</Nav.Link>
          </Link>
        </Nav>
        <Nav className={styles["right-padding"] + " ml-auto"}>
          <NavDropdown title={ 
            <span className={styles['nav-dropdown-text']}>{dropdownName}</span>
          } id="basic-nav-dropdown" style={{fontSize: "4rem !important"}}>
            <Link href="/profile" passHref>
              <NavDropdown.Item>View Profile</NavDropdown.Item>
            </Link>
            <Link href="/edit-profile" passHref>
              <NavDropdown.Item>Edit Profile</NavDropdown.Item>
            </Link>
            <Link href="/account" passHref>
              <NavDropdown.Item>Settings</NavDropdown.Item>
            </Link>
            <a className="dropdown-item" onClick={() => logoutUser()} href="javascript:void(0)">Log out</a>
          </NavDropdown>
        </Nav>
      </>
    )
  }

  const landingNav = () => {
    return(
      <Navbar bg="none" expand="xl" className={styles['navigator'] + " " + styles['landing-nav']} variant="dark">
        <Link href="/" passHref>
          <Navbar.Brand className={styles['navbar-brand']} style={{width: "260px"}}><img src="/white-logo.svg"></img></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="navbar-nav ml-auto mx-auto">

            <Link href={"/salary-calculator"} passHref shallow>
              <Nav.Link className={styles['nav-link']}>Wage Calculator</Nav.Link>
            </Link>
            <Link href={"/workers"} passHref shallow>
              <Nav.Link className={styles['nav-link']}>For Workers</Nav.Link>
            </Link>
            <Link href={"/employers"} passHref shallow>
              <Nav.Link className={styles['nav-link']}>For Contractors</Nav.Link>
            </Link>
            <Link href={"https://www.meetladder.com/jobs"} passHref shallow>
              <Nav.Link className={styles['nav-link']}>Jobs</Nav.Link>
            </Link>
            <Link href={"/blog"} passHref shallow>
              <Nav.Link className={styles['nav-link']}>Blog</Nav.Link>
            </Link>
          </Nav>
          <Nav className={styles["right-padding"] + " " + styles['right-nav']}>
            <Link href={frontendURL() + "/login"} passHref>
              <Nav.Link className={styles['nav-link']}>Login</Nav.Link>
            </Link>  
            <Link href={"/sign-up"} passHref>
              <Nav.Link className={styles['nav-link'] + " btn btn-primary" + " " + styles["demo-link"]}>
                Sign Up
              </Nav.Link>
            </Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }

  const organizationsNav = (dropdownName) => {
    if(!user.organizations || user.organizations.length === 0) return;

    return(
      <>
        <Nav className="mr-auto">
          <Link href={"/organizations/" + user.organizations[0].identifier} passHref>
            <Nav.Link className={styles['nav-link']}>{user.organizations[0].name} Dashboard</Nav.Link>
          </Link>
        </Nav>
        <Nav className={styles["right-padding"] + " ml-auto"}>
          <NavDropdown title={ 
            <span className={styles['nav-dropdown-text']}>{dropdownName}</span>
          } id="basic-nav-dropdown" style={{fontSize: "4rem !important"}}>
            <Link href="/account" passHref>
              <NavDropdown.Item>Settings</NavDropdown.Item>
            </Link>
            <a className="dropdown-item" onClick={() => logoutUser()} href="javascript:void(0)">Log out</a>
          </NavDropdown>
        </Nav>
      </>
    )
  }

  const showLoggedInNav = () => {
    if(lNav) {
      return false;
    } else if(route.pathname === "/") {
      return (typeof window !== undefined && window.location.href.includes("app"));
    }

    return true;
  }

  if(hide) {
    return(
      <Navbar bg="none" expand="lg" className={styles['navigator']} variant="dark">
     </Navbar>
    )
  } else if(domain && domain.includes("iec")) {
    return(
      <Navbar bg="none" expand="lg" className={styles['navigator']} variant="dark">
        <Link href="/" passHref>
          <Navbar.Brand className={styles['navbar-brand']}><img src={employerLogo ? employerLogo : (textColor ? `/${textColor}-logo.svg` : "/white-logo.svg")}></img></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link className={styles['nav-link']} style={{'color': 'white'}} disabled>Post a job listing here for free by becoming an IEC Member</Nav.Link> 
            <Link href={"https://iecatlantaga.com"} passHref>
              <Nav.Link className={styles['nav-link']+ " btn btn-primary" + " " + styles["demo-link"]}>IEC Atlanta/GA Home</Nav.Link>
            </Link>  
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  } else if(loggedIn && showLoggedInNav()) {
    return(
      <Navbar bg="none" expand="lg" className={styles['navigator']} variant="dark">
        <Link href="/" passHref>
          <Navbar.Brand className={styles['navbar-brand']}><img src="/white-logo.svg"></img></Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {authenticatedNav()}
      </Navbar>
    )
  } else if(route.pathname === "/e/[employer]" || route.pathname === "/jobs/[job]" || route.pathname.includes("/e/")) {
    return(
      <Navbar bg="none" expand="lg" className={styles['navigator']} variant="dark">
        <Link href={brandLink ? brandLink : "/"} passHref>
          <Navbar.Brand className={styles['navbar-brand']}><img src={employerLogo ? employerLogo : (textColor ? `/${textColor}-logo.svg` : "/white-logo.svg")}></img></Navbar.Brand>
        </Link>
        <Nav className={styles["right-padding"] + " " + styles['right-nav'] + " ml-auto"}>
          <Link href={frontendURL() + "/login"} passHref>
            <Nav.Link className={styles['nav-link']} style={{color: (textColor ? `${textColor}` : "#fff")}}>Login</Nav.Link>
          </Link>  
        </Nav>
      </Navbar>
    )
  } else {
    return landingNav();
  }
}
